const pricingPlans = [
  {
    id: 1,
    name: "Foundation Plan",
    description: "For companies beginning with automation",
    price: "12",
    additionalPrice: "1.10",
    features: [
      {
        id: 1,
        title: "Core HR",
        features: [
          {
            id: 1,
            title: "Organization Structure Management",
            description:
              "Visualize and manage your company's organizational hierarchy",
          },
          {
            id: 2,
            title: "Employee Onboarding (Basic)",
            description:
              "Streamline new hires with a guided onboarding experience",
          },
          {
            id: 3,
            title: "Dynamic Employee Profiles",
            description:
              "Maintain detailed, customizable profiles for every employee",
          },
          {
            id: 4,
            title: "Standard Access Roles",
            description: "Assign predefined roles for secure access control",
          },
          {
            id: 5,
            title: "Employee Exit Management",
            description:
              "Simplify offboarding with clear workflows and documentation",
          },
          {
            id: 6,
            title: "Documents Management",
            description: "Securely store and manage employee-related files",
          },
          {
            id: 7,
            title: "Notification Engine (Basic)",
            description:
              "Deliver essential notifications to employees and managers",
          },
          {
            id: 8,
            title: "Reports (Basic)",
            description: "Generate and view basic reports for HR insights",
          },
        ],
      },
      {
        id: 2,
        title: "Time & Attendance",
        features: [
          {
            id: 9,
            title: "Leave Management System",
            description: "Track and approve leave requests easily",
          },
          {
            id: 10,
            title: "Shift Management (Basic)",
            description: "Assign shifts and manage schedules efficiently",
          },
          {
            id: 11,
            title: "Holiday Calendar Customization",
            description:
              "Tailor holiday calendars to regional or organizational needs",
          },
          {
            id: 12,
            title: "Payroll & Expense (RazorPay)",
            description:
              "Integrate payroll and expense management with RazorPay",
          },
          {
            id: 13,
            title: "Payroll Automation",
            description: "Automate salary calculations and disbursement",
          },
          {
            id: 14,
            title: "Statutory Compliance (PF, ESI, TDS)",
            description: "Ensure compliance with local payroll regulations",
          },
          {
            id: 15,
            title: "Integration with Accounting Software",
            description: "Sync payroll data with accounting systems",
          },
          {
            id: 16,
            title: "Loans & Salary Advances",
            description: "Manage loans and salary advance requests seamlessly",
          },
          {
            id: 17,
            title: "Gratuity Management",
            description: "Automate gratuity calculations based on tenure",
          },
          {
            id: 18,
            title: "Perks & Benefits Management",
            description: "Administer employee perks and benefits programs",
          },
          {
            id: 19,
            title: "Expense Management (Basic)",
            description: "Track and manage employee expense claims",
          },
          {
            id: 20,
            title: "Pay Slips and Tax Forms Distribution",
            description: "Distribute pay slips and tax forms digitally",
          },
        ],
      },
      {
        id: 3,
        title: "Employee Self-Service",
        features: [
          {
            id: 21,
            title: "Employee Portal",
            description:
              "Empower employees with a centralized self-service platform",
          },
          {
            id: 22,
            title: "Mobile App Access",
            description: "Access HR tools on the go via the mobile app",
          },
          {
            id: 23,
            title: "Basic Inbox Notifications",
            description: "Receive important updates directly in your inbox",
          },
          {
            id: 24,
            title: "Profile Updates",
            description: "Allow employees to update their personal information",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement",
        features: [
          {
            id: 25,
            title: "Rewards and Leaderboards",
            description: "Recognize top performers and drive motivation",
          },
          {
            id: 26,
            title: "Managerial Challenges for Incentives",
            description: "Engage managers with performance-linked challenges",
          },
          {
            id: 27,
            title: "Employee Surverys (Basic)",
            description: "Gather employee feedback through surveys",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Strength Plan",
    description:
      "For companies scaling with advanced automation and employee engagement",
    price: "15",
    additionalPrice: "1.80",
    features: [
      {
        id: 1,
        title: "Core HR Plus",
        features: [
          {
            id: 1,
            title: "Advanced Employee Onboarding (Customisable worklows)",
            description: "Tailor onboarding workflows to your needs",
          },
          {
            id: 2,
            title: "Role-Based Access Controls",
            description: "Assign permissions based on specific roles",
          },
          {
            id: 3,
            title: "Employee Productivity Tracking",
            description: "Monitor and enhance employee productivity",
          },
        ],
      },
      {
        id: 2,
        title: "Time & Attendance Plus",
        features: [
          {
            id: 4,
            title: "Customisable Salary Structures",
            description: "Define and implement tailored salary components",
          },
          {
            id: 5,
            title: "Advanced Expense Policies",
            description: "Set detailed expense guidelines and rules",
          },
          {
            id: 6,
            title: "Multiple Payroll Cycles",
            description: "Manage payroll across different cycles seamlessly",
          },
        ],
      },
      {
        id: 3,
        title: "Employee Self-Service Plus",
        features: [
          {
            id: 7,
            title: "Single Sign-On (SSO)",
            description: "Simplify logins with a unified authentication method",
          },
          {
            id: 8,
            title: "Mobile OTP Login",
            description: "Enhance security with OTP-based mobile logins",
          },
          {
            id: 9,
            title: "Notification Center (Advanced)",
            description: "Manage and deliver advanced notifications",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement Plus",
        features: [
          {
            id: 10,
            title: "Customizable Rewards According to Company Policies",
            description: "Align rewards with company policies",
          },
        ],
      },
      {
        id: 4,
        title: "Performance Management",
        features: [
          {
            id: 11,
            title: "Goal Setting & Tracking (Basic)",
            description: "Align and track employee goals",
          },
          {
            id: 12,
            title: "Performance Reviews (Basic)",
            description: "Conduct structured performance evaluations",
          },
        ],
      },
      {
        id: 6,
        title: "Analytics and Reporting",
        features: [
          {
            id: 13,
            title: "Customizable Reports",
            description: "Generate detailed, tailored reports",
          },
          {
            id: 14,
            title: "Detailed Attendance Analytics",
            description: "Gain insights into attendance patterns",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Growth Plan",
    description:
      "For companies aiming to align employees in their growth journey and maximize performance",
    price: "20",
    additionalPrice: "2.40",
    features: [
      {
        id: 1,
        title: "Core HR Premium",
        features: [
          {
            id: 1,
            title: "Custom HR Workflows",
            description: "Design and automate unique HR processes",
          },
          {
            id: 2,
            title: "Advanced Notification Engine (Worklow-Based Alerts)",
            description: "Deliver workflow-based alerts",
          },
          {
            id: 3,
            title: "Talent Acquisition Support (Recruitment Module)",
            description: "Streamline hiring with a recruitment module",
          },
        ],
      },
      {
        id: 2,
        title: "Advanced Time & Attendance",
        features: [
          {
            id: 4,
            title: "Shift Pattern Forecasting",
            description: "Predict and plan shift patterns effectively",
          },
          {
            id: 5,
            title: "Scheduling Based on Demand",
            description: "Optimize workforce scheduling to meet demands",
          },
        ],
      },
      {
        id: 3,
        title: "Performance Management",
        features: [
          {
            id: 6,
            title: "OKRs (Objectives and Key Results)",
            description: "Drive alignment through OKRs",
          },
          {
            id: 7,
            title: "360-Degree Feedback",
            description: "Gather holistic performance feedback",
          },
          {
            id: 8,
            title: "Continuous Feedback Loop",
            description: "Foster ongoing feedback and improvement",
          },
          {
            id: 9,
            title: "Skill-Based Performance Tracking",
            description: "Evaluate performance based on skills",
          },
        ],
      },
      {
        id: 4,
        title: "Employee Self-Service Premium",
        features: [
          {
            id: 10,
            title: "Access via Chatbot Integration",
            description: "Provide self-service through chatbots",
          },
          {
            id: 11,
            title: "Custom Dashboards for Employees",
            description: "Deliver personalized dashboards",
          },
          {
            id: 12,
            title: "Advanced Notification Center with Reminders",
            description: "Send timely, actionable reminders",
          },
        ],
      },
      {
        id: 5,
        title: "Employee Engagement & Retention",
        features: [
          {
            id: 13,
            title: "Advanced Employee Surveys with Analytics",
            description: "Gain deeper insights from surveys",
          },
          {
            id: 14,
            title: "Engagement Heatmaps",
            description: "Visualize engagement trends across teams",
          },
          {
            id: 15,
            title: "Rewards and Recognition Programs Integration",
            description: "Enhance recognition efforts",
          },
          {
            id: 16,
            title: "Exit Interview Management",
            description: "Streamline exit interviews and insights",
          },
        ],
      },
      {
        id: 6,
        title: "Analytics and Insights",
        features: [
          {
            id: 17,
            title: "Predictive Workforce Analytics",
            description: "Anticipate workforce trends",
          },
          {
            id: 18,
            title: "Custom Dashboards for HR and Leadership",
            description: "Deliver tailored insights for decision-makers",
          },
          {
            id: 19,
            title: "Attrition & Retention Analysis",
            description: "Understand and address retention challenges",
          },
        ],
      },
      {
        id: 7,
        title: "Learning & Development",
        features: [
          {
            id: 20,
            title: "Training Management System",
            description: "Organize and track employee training programs",
          },
          {
            id: 21,
            title: "Skill Gap Assessments",
            description: "Identify and address skill gaps",
          },
          {
            id: 22,
            title: "Learning Paths and Certification Tracking",
            description: "Create and track employee learning journeys",
          },
        ],
      },
      {
        id: 8,
        title: "Support",
        features: [
          {
            id: 23,
            title: "Dedicated Account Manager",
            description: "Receive personalized support",
          },
          {
            id: 24,
            title: "Priority Support",
            description: "Get faster issue resolution",
          },
          {
            id: 25,
            title: "Onboarding Support",
            description: "nsure smooth onboarding for your team",
          },
        ],
      },
      {
        id: 9,
        title: "Premium Payroll & Expense Features (In Production)",
        features: [
          {
            id: 26,
            title: "Payroll Reconciliation Dashboard",
            description: "Simplify payroll adjustments",
          },
          {
            id: 27,
            title: "Global Payroll (Multi-Currency Support)",
            description: "Manage international payroll",
          },
          {
            id: 28,
            title: "Travel Expenses Reimbursement Automation",
            description: "Automate travel expense claims",
          },
        ],
      },
    ],
  },
];

export default pricingPlans;
